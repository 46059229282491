<template>
    <div>
        <!-- TEST TEST TEST -->
    <v-dialog
        transition="dialog-top-transition"
        max-width="400"
        v-model="visible"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          >From the top</v-btn>
        </template> -->
        <template v-slot:default="dialog">
          <v-card class="text-center">
            <div style="min-height: 2px;"></div>
            <v-img :src="selectedStep.icon" style="width: 200px; margin-top: 80px; margin-left: auto; margin-right: auto;" />
            <!-- <v-toolbar
              color="primary"
              dark
            >Opening from the top</v-toolbar> -->
            <v-card-text>
              <div class="text-h5 pa-8">{{selectedStep.message}}</div>
              <div class="pa-2">{{selectedStep.subtitle}}</div>
              <!-- <div>
                <ul class="slick-dots">
                  <li>
                    <button></button>
                  </li>
                  <li>
                    <button></button>
                  </li>
                </ul>
              </div> -->
              <div class="container">
                <span v-for="(step, index) in steps" :key="index" @click="setStep(index)" class="dot" :class="index===currentStepIdx ? 'active' : ''"></span>                
            </div>
            </v-card-text>

            <v-card-actions class="pb-4" style="flex-direction: column">
              <v-btn v-if="selectedStep.next_button" @click="goToNextStep" class="text-capitalize" block color="primary">                                     
                    {{selectedStep.next_button_text}}
              </v-btn>
              <v-btn v-if="selectedStep.action_button" @click="customAction(index)" class="text-capitalize" block color="primary">                                     
                    {{selectedStep.action_button_text}}
              </v-btn>
              <v-btn v-if="selectedStep.skip_button" @click="closeGuide" class="text-capitalize mt-2 ml-0" block outlined color="black">                                     
                    {{selectedStep.skip_button_text}}
              </v-btn>
              
              <!-- <v-btn
                text
                @click="dialog.value = false"
              >Close</v-btn> -->
            </v-card-actions>
            <!-- <v-tour name="myTour" :steps="teststeps" :callbacks="tourCallbacks"></v-tour> -->
          </v-card>
        </template>
      </v-dialog>
      </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
//import { items } from "../../data/navigation";

export default {
  // components: {
  //   VuePerfectScrollbar,
  // },

  data() {
    var self=this;
    return {
        test: false,
        currentStepIdx: 0,
        currentSubStepIdx: 0,
        tourTarget: '',
        tourSteps: [],
        tourCallbacks: {
          onStart: this.tourCallbackOnStart,
          onPreviousStep: this.tourCallbackOnPreviousStep,
          onNextStep: this.tourCallbackOnNextStep,
          onSkip: this.tourCallbackOnSkip,
          onFinish: this.tourCallbackOnFinish,
          onStop: this.tourCallbackOnStop,
        },
        steps: [

          // {
          //   icon: require('@/assets/images/onboarding/confetti.gif'),
          //   message: 'Congratulations',
          //   subtitle: 'You have completed your first booking. Now sit back and relax, until your session...',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },

          // {
          //   icon: require('@/assets/images/onboarding/massage.gif'),
          //   message: 'Congratulations',
          //   subtitle: 'You have completed your first booking. Now sit back and relax, until your session...',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },


          // {
          //   icon: require('@/assets/images/onboarding/face-massage.gif'),
          //   message: 'Congratulations',
          //   subtitle: 'You have completed your first booking. Now sit back and relax, until your session...',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },


          // {
          //   icon: require('@/assets/images/onboarding/hiring.gif'),
          //   message: 'Are you a professional?',
          //   subtitle: 'Switch to a professional profile, so you can start accepting appointments',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },

          // {
          //   icon: require('@/assets/images/onboarding/bed.gif'),
          //   message: 'Are you a professional?',
          //   subtitle: 'Switch to a professional profile, so you can start accepting appointments',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },



          // {
          //   icon: require('@/assets/images/onboarding/map.gif'),
          //   message: 'Allow us to know your location',
          //   subtitle: 'That way we can automatically detect where you will be having your appointments.',
          //   next_button: false,
          //   next_button_text: 'OK',
          //   skip_button: true,
          //   skip_button_text: 'OK',
          //   action_button: false,
          //   action_button_text: 'next',
          //   action_button_target: 'next',                        
          // },




          {
            icon: require('@/assets/images/onboarding/welcome.gif'),
            message: 'Welcome to MassageApp',
            subtitle: 'Follow these steps to learn your way around',
            next_button: true,
            next_button_text: 'Let\'s Go!',
            skip_button: true,
            skip_button_text: 'Skip Guide',
            action_button: false,
            action_button_text: 'next',
            action_button_target: 'next',                        
          },
          {
            icon: require('@/assets/images/onboarding/user-profile.gif'),
            message: 'Complete you profile',
            subtitle: 'Find out how you can complete the rest of your information',
            next_button: false,
            next_button_text: 'Next',
            skip_button: false,
            skip_button_text: 'Skip Guide',
            action_button: true,
            action_button_text: 'Complete Profile',            
            // tour: [
            //   {                
            //     target: '#headeravatar',
            //     content: 'Click here to view your Account details.'
            //   },
            // ]
            tour: [
              {
                preaction: () => {
                  try {
                    self.$router.push("/mng/homepage"); 
                  } catch (error) {
                    
                  }                  
                  self.tourTarget = 'myTour';
                  //self.tourSteps = self.selectedStep.tour[self.currentSubStepIdx].steps;
                  self.setTourSteps(self.selectedStep.tour[self.currentSubStepIdx].steps);
                  self.visible = false;
                  self.$tours[self.tourTarget].start();
                },
                postaction: () => {
                  document.getElementById("userdrawerclicktarget").click();
                },
                steps: [
                  {                
                    target: '#headeravatar',
                    content: 'Click here to view your Account details.'
                  },
                  // {
                  //   target: '',
                  //   content: 'Click here to access your profile details.'
                  // },
                ]
              },              
            ]
          }
        ],        
        // ...mapState({
        //     visible: 'onboardingDialogVisible',
        // }),
    //   background: true,
    //   items: items,
    //   opacity: 0.4,
    };
  },
  computed: {
    visible: {
        get() {            
            //return this.test;
            return this.$store.state.onboarding.onboardingDialogVisible
        },
        set(value) {
            //this.test = value;
            this.$store.commit('updateOnboardingDialogVisible', value)
        }
    },
    selectedStep() {
      return this.steps[this.currentStepIdx];
    }
    // bg() {
    //   return this.background
    //     ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
    //     : undefined;
    // },

    // computedItems() {
    //   return this.items.map(this.mapItem);
    // },

    // ...mapGetters(["getThemeMode"]),

    // showMenu() {
    //   let ttt = getThemeMode.verticalSaasSidebarDrawer;
    //   let userData = localStorage.getItem("userInfo");
    //   let enableSidebarDrawer = false;
    //   if(userData) {
    //     if(JSON.parse(localStorage.getItem("userInfo")).user.role !== "customer") {
    //       enableSidebarDrawer = true
    //     }
    //   }

    //   return ttt && enableSidebarDrawer;
    // }
  },
  methods: {
    ...mapMutations(['setTourSteps']),
    goToNextStep: function() {
      if(this.currentStepIdx < (this.steps.length - 1)) {
        this.currentStepIdx++;
      }
    },
    goToPreviousStep: function() {
      if(this.currentStepIdx >= 1) {
        this.currentStepIdx--;
      }
    },
    closeGuide: function() {
      this.visible = false;
    },
    setStep(targetIndex) {
      this.currentStepIdx = targetIndex;
    },
    customAction(targetIndex) {
      this.steps[this.currentStepIdx].tour[this.currentSubStepIdx].preaction();
      //this.$tours[this.tourTarget].start();
    },
    tourCallbackOnStart(currentStepIndex) {

    },
    tourCallbackOnPreviousStep(currentStepIndex) {

    },
    tourCallbackOnNextStep(currentStepIndex) {

    },
    tourCallbackOnSkip(currentStepIndex) {

    },
    tourCallbackOnFinish(currentStepIndex) {
      alert("tour finish " + currentStepIndex);
      this.steps[this.currentStepIdx].tour.postaction();
    },
    tourCallbackOnStop(currentStepIndex) {

    }
    // mapItem(item) {
    //   return {
    //     ...item,
    //     children: item.children ? item.children.map(this.mapItem) : undefined,
    //     title: item.title,
    //   };
    // },
  },
};
</script>

<style>
.ps-container {
  position: inherit !important;
}

.container {
  	display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    
    list-style-type: none;
}

.dot {
    /* background-color: #eee;
    border: 1px solid #666;
    border-radius: 5px;
    box-shadow: inset 1px 1px 1px #888;
    display: inline-block;
    height: 10px;
    width: 10px; */
    cursor: pointer;
    margin: 0 0.25rem;
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    padding: 0;
    
    border: none;
    border-radius: 100%;
    background-color: lightgray;
    
    text-indent: -9999px;
}
.dot.active {
    /* background-color: #41ABE5;
    box-shadow: inset 2px 0px 2px -2px #333; */
    background-color: #eb8e45;
}

.slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 1rem 0;
	
	list-style-type: none;
	
		li {
			margin: 0 0.25rem;
		}
	
		button {
			display: block;
			width: 1rem;
			height: 1rem;
			padding: 0;
			
			border: none;
			border-radius: 100%;
			background-color: orange;
			
			text-indent: -9999px;
		}
	
		li.slick-active button {
			background-color: red;
		}
	
}

@media screen and (max-width: 1024px) {
  /* .vertical-sass-sidebar {
    position: fixed;
    left: -251px;
  } */
}
</style>
